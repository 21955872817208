import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { DataGrid, GridColumnHeaders } from '@mui/x-data-grid';
import CustomPagination from './pagination';
import LoadingSkeleton from './skeleton';
import PropTypes from 'prop-types';

const GridWrapper = styled(Grid)(
  ({ theme }) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }
    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-selectedRowCount {
      visibility: hidden;
    }
    .MuiTablePagination-selectLabel, .MuiTablePagination-select, svg.MuiSvgIcon-root.MuiTablePagination-selectIcon {
      visibility: hidden;
    }
    .page-count {
      font-size: 15px;
      bottom: 15px;
      position: absolute;
      right: 100px;
      display: none;
    }
    .match-categories  {
      .header-column {
        text-align: center;
        border-right: 3px solid ${theme.palette.gray.light};
      }
      .cat-col {
        width: 110px;
      }
      .source-col {
        width: 336px;
      }
      .match-col {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 1;
      }
      .row-1 {
        height: 70px;
        padding-top: 20px;
      }
      .row-2 {
        background-color: ${theme.palette.background.default};
        text-align: left;
      }
      .header {
        display: flex;
        &.lower {
          border-bottom: 3px solid ${theme.palette.gray.dark};
          .row-2-cell {
            padding-left: 5px;
            font-weight: 700;
            padding-bottom: 10px;
            padding-top: 10px;
          }
        }
      }
      .ssn {
        padding-left: 10px;
      }
      .wrap-cell {
        height: 50px;
        padding-top: 12px;
        width: 100%
      }
      .wrap-cell.border {
        border-left: 3px solid ${theme.palette.gray.light};
        padding-left: 10px;
      }
      .MuiDataGrid-row {
        border-bottom: none;
      }
      .MuiDataGrid-row:nth-of-type(even), .MuiDataGrid-row:nth-of-type(even):hover  {
        background-color: ${theme.palette.primary.hover} !important;
      }
      .MuiDataGrid-footerContainer {
        display: none;
      }
    }
  `
)

function NoRows() {
  return (
    <Typography sx={{ m: 4 }}>No lists to display.</Typography>
  )
}

function TableWithActionButton(props) {

  const customHeader = () => (
    <>
      <Grid container className="header">
        <Grid item className="header-column cat-col row-1">
          <Typography variant="body1" sx={{ fontWeight: 700 }}>CATEGORY</Typography>
        </Grid>
        <Grid item className="header-column source-col row-1">
          <Typography variant="body1" sx={{ fontWeight: 700 }}>SOURCE</Typography>
        </Grid>
        <Grid item className="header-column match-col row-1">
          <Typography variant="body1" sx={{ fontWeight: 700 }}>MATCH CRITERIA</Typography>
        </Grid>
      </Grid>
      <Grid container className="header lower">
        <Grid item className="header-column cat-col row-2">
          <Grid container>
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>&nbsp;</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="header-column source-col row-2">
          <Grid container>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">DMF</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">State</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className="row-2-cell">Other <br />Carriers</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">Obituary</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="header-column match-col row-2">
          <Grid container>
            <Grid item sx={{width: '110px', maxWidth: '110px'}}>
              <Typography variant="body1" className="row-2-cell" sx={{ fontWeight: 700 }}>SSN</Typography>
            </Grid>
            <Grid item sx={{width: '14.2%'}}>
              <Typography variant="body1" className="row-2-cell">DOB</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">First<br />Name</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">Last<br />Name</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">City</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">State</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  const [pageSize, setPageSize] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const handleChangePage = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  return (
    <GridWrapper container>
      {props.useAutoRowHeight ?
        <div style={{ height: props.tableHeight, width: '100%' }}>
          <DataGrid pageSize={pageSize}
            initialState={{
              sorting: {
                sortModel: [props?.sortKey || 'fileName'],
              },
            }}
            className={props?.customClass}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[1, 2, 5, 10, 20]}
            rows={props.rows}
            columns={props.columns}
            getEstimatedRowHeight={() => 100}
            getRowHeight={() => 'auto'}
            pagination
            hideFooterPagination={props?.hideFooterPagination}
            disableColumnMenu={true}
            onPageChange={handleChangePage}
            loading={props.isDataTableLoading}
            slots={{
              toolbar: props?.useHeader ? customHeader : '',
              Header: CustomPagination,
              NoRowsOverlay: NoRows,
              LoadingOverlay: () => LoadingSkeleton(props),
              columnHeaders: props?.useHeader ? () => null : GridColumnHeaders
            }}
            slotProps={{
              header: { page: page },
            }}
            sx={props?.alignContent === 'start' && {
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignContent: 'start',
                flexWrap: 'wrap'
              },
            }}
          />
        </div> :
        <div style={{ height: props.tableHeight, width: '100%', minHeight: '270px' }}>
          <DataGrid pageSize={pageSize}
            initialState={{
              sorting: {
                sortModel: [props?.sortKey],
              },
            }}
            className={props?.customClass}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[1, 2, 5, 10, 20]}
            rows={props.rows}
            columns={props.columns}
            rowHeight={props.rowHeight}
            disableColumnMenu={true}
            onPageChange={handleChangePage}
            loading={props.isDataTableLoading}
            slots={{
              Header: CustomPagination,
              NoRowsOverlay: NoRows,
              LoadingOverlay: () => LoadingSkeleton(props)
            }}
            slotProps={{
              header: { page: page },
            }}
            sx={props?.alignContent === 'start' && {
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignContent: 'start',
                flexWrap: 'wrap'
              },
            }}
          />
        </div>
      }
    </GridWrapper>
  )
}

TableWithActionButton.propTypes = {
  useAutoRowHeight: PropTypes.bool,
  rows: PropTypes.array,
  columns: PropTypes.array,
  isDataTableLoading: PropTypes.bool,
  tableHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  sortKey: PropTypes.object,
  useHeader: PropTypes.bool,
  customClass: PropTypes.string,
  hideFooterPagination: PropTypes.bool,
  alignContent: PropTypes.string
}

export default TableWithActionButton;
