import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';

import { FormControlLabel, Radio, RadioGroup, TextField, Typography, Box, FormHelperText, FormControl, MenuItem, Grid } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import PropTypes from 'prop-types';

import { numDaysPerMonth, months } from 'src/utilities/months';
import { combineMonthDay } from 'src/utilities/combineMonthDay';
import AnnualDmfDisplay from 'src/components/AnnualDmfDisplay';

export const annualDmfInitialValues = {
  enableAnnualDmf: false,
  annualDmfDay: '',
  annualDmfMonth: ''
}

export const annualDmfSchema = {
  enableAnnualDmf: yup
    .boolean(),
  annualDmfMonth: yup
    .number()
    .when(['enableAnnualDmf'], (annualDmfEnabled) => {
      if (annualDmfEnabled) {
        return yup.number().required();
      }
    }),
  annualDmfDay: yup
    .number()
    .when(['enableAnnualDmf', 'annualDmfMonth'], (annualDmfEnabled, month) => {
      if (annualDmfEnabled && month) {
        return yup.number().required();
      }
    })
}

export function MonthDaySelect({ formik, variant='setup' }) {
  const variantMap = {
    setup: {
      includeLeadTimeCopy: true
    },
    edit: {
      includeLeadTimeCopy: false
    }
  }
  
  const handleMonthChange = (event) => {
    formik.setFieldValue('annualDmfDay', '');
    formik.setFieldTouched('annualDmfDay', false);
    formik.handleChange(event);
  }
  
  return (
    <Grid container sx={{ mt: 0.5 }} spacing={2}>
      <Grid item xs={variantMap?.[variant]?.includeLeadTimeCopy ? 4 : 6}>
    <FormControl sx={{ width: "100%" }}>
      <TextField
        select
        id="annualDmfMonth"
        name="annualDmfMonth"
        value={formik.values.annualDmfMonth}
        onChange={(event) => handleMonthChange(event)}
        onBlur={formik.handleBlur}
        label="Month"
        error={formik.touched.annualDmfMonth && Boolean(formik.errors.annualDmfMonth)}
      >
        {months.map((month, k) => {
          return <MenuItem key={`annualDmfMonth-${month}`} value={k+1}>{month}</MenuItem>
        })}
      </TextField>
      {formik?.touched?.annualDmfMonth && formik?.errors?.annualDmfMonth ? 
        <FormHelperText error><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />Selection is required.</FormHelperText>
        :
        <FormHelperText>Delivered on this date once each contract year.</FormHelperText>
      }
    </FormControl>
    </Grid>
    <Grid item xs={variantMap?.[variant]?.includeLeadTimeCopy ? 2 : 4}>
    <FormControl sx={{ width: "100%" }}>
      <TextField
        select
        id="annualDmfDay"
        name="annualDmfDay"
        disabled={formik?.values?.annualDmfMonth === ''}
        value={formik.values.annualDmfDay}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label="Day"
        error={formik.touched.annualDmfDay && Boolean(formik.errors.annualDmfDay)}
      >
        {[...Array(numDaysPerMonth[formik.values.annualDmfMonth-1])].map((val, i) => {
          const day = i + 1;
          return <MenuItem key={`annualDmfDay-${day}`} value={day}>{day}</MenuItem>
        })}
      </TextField>
      {formik?.touched?.annualDmfDay && formik?.errors?.annualDmfDay &&
        <FormHelperText error><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />Selection is required.</FormHelperText>
      }
    </FormControl>
    </Grid>
    {variantMap?.[variant]?.includeLeadTimeCopy &&
      <Grid item xs={6}>
        <Typography sx={{ mt: 0.75 }} variant="body2" color="text.secondary">Select a date 7 days from today's date to allow enough time for processing.</Typography>
      </Grid>
    }
    </Grid>
  );
}

MonthDaySelect.propTypes = {
  formik: PropTypes.object,
  variant: PropTypes.string
};

export function AnnualDmfScanSetup({ formik, filePrefix, fileType, matchConfigs, customerConfigs }) {

  const handleEnableChange = (event) => {
    formik.setFieldValue('annualDmfDay', '');
    formik.setFieldTouched('annualDmfDay', false);
    formik.setFieldValue('annualDmfMonth', '');
    formik.setFieldTouched('annualDmfMonth', false);
    formik.handleChange(event);
  }

  const dmfAnnualDate = formik?.values?.annualDmfMonth && formik?.values?.annualDmfDay && ((new Date()).getFullYear() + combineMonthDay(formik?.values?.annualDmfMonth, formik?.values?.annualDmfDay));

  const dmfAnnualMatchConfig = _.find(matchConfigs, (matchConfig) => _.has(matchConfig, 'transfer.annualDmf'));

  return (
    dmfAnnualMatchConfig ? 
      <AnnualDmfDisplay frequencies={{scanFrequencies: ['dmf-annual']}} variant='list' customerConfigs={customerConfigs} matchConfigs={matchConfigs} />
      :
      <>
        <Typography variant="subtitle1" mt={1}>Include annual full DMF scans?</Typography>
        <RadioGroup
          id="enableAnnualDmf"
          name="enableAnnualDmf"
          value={formik.values.enableAnnualDmf}
          onChange={(event) => handleEnableChange(event)}
          sx={{ my: 2, ml: 1 }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes, send annual full DMF scans. One match results file to be delivered once per contract year." />
          {formik.values.enableAnnualDmf === 'true' && 
            <Box ml={4} mt={1} mb={1}>
              <Typography color="text.secondary">Choose match file delivery date:</Typography>
              <MonthDaySelect formik={formik} />
              <Typography mt={2} color="text.secondary">File Name Example:</Typography>
              <Typography whiteSpace="pre-line">{fileType === 'both' ? `${filePrefix}_dmf_annual-${dmfAnnualDate}.json\n${filePrefix}_dmf_annual-${dmfAnnualDate}.xlsx` : `${filePrefix}_dmf_annual-${dmfAnnualDate}.${fileType}`}</Typography>
            </Box>
          }
          <FormControlLabel value={false} control={<Radio />} label="No, do not send annual full DMF scans." />
        </RadioGroup>
        <Typography>Within each contract year, only one customer list at a time can undergo an annual DMF scan.</Typography>
      </>
  );
}

AnnualDmfScanSetup.propTypes = {
  formik: PropTypes.object.isRequired,
  filePrefix: PropTypes.string,
  fileType: PropTypes.string,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array
};