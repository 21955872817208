import {DateTime} from 'luxon';


export function getDateWithTimezone(startObj, startOpts={zone: 'America/Chicago'}, format='H a ZZZZ'){
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return DateTime.fromObject(startObj, startOpts).setZone(timeZone).toFormat(format);
}

export default getDateWithTimezone;
