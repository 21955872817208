import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Typography, Grid, Tooltip, Box, Card, CardContent, Alert, styled } from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';

import { useAppContext } from 'src/AppContext.js';
import { requestMatchFileTest } from 'src/api';
import { getCurrentDate } from 'src/utilities/getDate';
import LoadingIcon from 'src/components/Loading/loadingIcon';

const LinkWrapper = styled(Typography)(
  ({ theme }) => `
    a:-webkit-any-link {
      color: ${theme.colors.primary.main}
    }
  `
);

function TestFile({ type, fileType, filePrefix, customerFilePrefix, setShowSuccess, cancel }) {
  const { carrierConfig } = useAppContext();

  const [genericError, setGenericError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentDate = getCurrentDate();

  const customerVdfListMap = {
    cardHeader: {
      title: 'Submit a Test File',
      subtitle: 'To verify the file configurations are correct, submit to LENS a test file.'
    },
    cardBody: {
      name: {
        label: 'Name your test file exactly as shown:',
        fileName: `test_${filePrefix}-${currentDate}.${fileType}`,
        subtitle: ''
      },
      transfer: {
        body: `Submit your test file to the transfer location according to the pre-configured setting for the designated ${type?.toLowerCase()}.`
      },
      find: {
        body: 'After submitting your test file, LENS runs it through the standard process to validate the file configurations. If any issues are encountered, an error report will be available for download from the Data Loads section.',
        link: <Link to={type === 'Customer List' ? '/customer-file-status' : '/verified-deaths-file-status'}>Data Loads</Link>
      }
    }
  };

  const typeMap = {
    'Customer List': customerVdfListMap,
    'Verified Deaths List': customerVdfListMap,
    'Match Results': {
      cardHeader: {
        title: 'Request a Match Results File',
        subtitle: 'To verify the file configurations are correct, request from LENS a test file.'
      },
      cardBody: {
        name: {
          label: 'Match results test file name:',
          fileName: fileType === 'both' ? `test_${filePrefix}-${currentDate}.json\ntest_${filePrefix}-${currentDate}.xlsx` : `test_${filePrefix}-${currentDate}.${fileType}`,
          subtitle: 'LENS uses this name to create and deliver your match results test file.'
        },
        transfer: {
          body: "After a test file request is initiated, LENS will encrypt and deliver the match results test file to your organization's specified transfer location according to the pre-configured settings for the designated match results file."
        },
        find: {
          body: 'If any issues are encountered during the match results test file delivery, an error report will be available for download in the Match Results Activity section.',
          link: <Link to='/matches-file'>Match Results Activity</Link>
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, [type]);

  const requestTestFile = async () => {
    try {
      setGenericError(false);
      setShowSuccess(false);
      setIsLoading(true);

      const response = await requestMatchFileTest(carrierConfig?.carrierId, customerFilePrefix);

      if (response.statusCode === 400 || response.statusCode === 500) {
        setGenericError(true);
        window.scrollTo(0, 0);
        setIsLoading(false);
      }
      
      if (response.statusCode === 200) {
        setIsLoading(false);
        setShowSuccess(true);
        cancel();
      }
    } catch (error) {
      setGenericError(true);
      window.scrollTo(0, 0);
      setIsLoading(false);
    }
  }

  return (
    type && 
      <>
        {isLoading && <LoadingIcon />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary.dark"><Tooltip arrow title="Go Back"><ArrowBackRounded className="back-arrow" onClick={cancel} sx={{ mr: 2 }} /></Tooltip> Test File Configurations</Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert className={genericError ? 'show' : 'hide'} severity="error">We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box m={2}>
                <Typography variant="subtitle1">{typeMap[type].cardHeader.title}</Typography>
                <Typography>{typeMap[type].cardHeader.subtitle}</Typography>
                </Box>
                <hr/>
                <CardContent>
                  <Box m={2}>
                    <Typography variant="subtitle1" mb={2} color="text.secondary" fontWeight={700}>NAME OF TEST FILE</Typography>
                    <Typography mb={1}><strong>{typeMap[type].cardBody.name.label}</strong></Typography>
                    <Typography mb={2} sx={{ whiteSpace: 'pre-line' }}>{typeMap[type].cardBody.name.fileName}</Typography>
                    <Typography variant="body2" mb={4}>{typeMap[type].cardBody.name.subtitle}</Typography>
                    <hr/>
                    <Typography variant="subtitle1" mt={4} mb={2} color="text.secondary" fontWeight={700}>FILE TRANSFER</Typography>
                    <Typography mb={2}>{typeMap[type].cardBody.transfer.body}</Typography>
                    <LinkWrapper variant="body2" mb={4}>Pre-configured settings are located on <Link onClick={cancel}>List Configurations</Link></LinkWrapper>
                    <hr/>
                    <Typography variant="subtitle1" mt={4} mb={2} color="text.secondary" fontWeight={700}>WHERE TO FIND RESULTS</Typography>
                    <Typography mb={2}>{typeMap[type].cardBody.find.body}</Typography>
                    <LinkWrapper variant="body2" mb={2}>Track test file's status on {typeMap[type].cardBody.find.link}</LinkWrapper>
                  </Box>
                </CardContent>
                <hr/>
                {type === 'Match Results' ?
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button sx={{ mb: 2, mt: 1, ml: 2 }} variant="outlined" size="large" onClick={cancel}>Close</Button>
                    <Button sx={{ mb: 2, mt: 1, mr: 2 }} variant="contained" size="large" onClick={requestTestFile}>Request Test File</Button>
                  </Box>
                  :
                  <Box textAlign="center">
                    <Button sx={{ mb: 2, mt: 1 }} variant="contained" size="large" onClick={cancel}>Close</Button>
                  </Box>
                }
            </Card>
          </Grid>
        </Grid>
      </>
  )
}

TestFile.propTypes = {
  type: PropTypes.string,
  fileType: PropTypes.string,
  filePrefix: PropTypes.string,
  customerFilePrefix: PropTypes.string,
  setShowSuccess: PropTypes.func,
  cancel: PropTypes.func
};

export default TestFile;