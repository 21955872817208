import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, styled, Box, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore, InfoRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import DisplayQuarterlyDates from '../DisplayQuarterlyDates';
import { months } from 'src/utilities/months';


function DisplayMoreDetails({ title, auditDate, auditUser, frequency, reportDates, frequencyDay, holidayCalendarId, annualDmf, emails }) {

  const EmailAccordionWrapper = styled(Box)(
    () => `
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    `
  );

  const [showEmails, setShowEmails] = useState(false);

  function toggleShowEmails() {
    setShowEmails(!showEmails);
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="caption">Added {auditDate} by {auditUser}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1, mt: 1 }}>
        <Typography variant="subtitle1">Transfer Schedule: <Typography sx={{textTransform: 'capitalize'}} variant="body1" display="inline">{frequency} {frequencyDay}</Typography></Typography>
        {title === 'Match Results Details' && <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'><InfoRounded color="primary" /></Tooltip>}
      </Box>
      {title === 'Match Results Details' && frequency === 'Quarterly' &&
        <DisplayQuarterlyDates reportDates={reportDates} />                        
      }
      {title === 'Match Results Details' && frequency === 'Daily (Monday-Friday)' && 
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5 }}>
          <Typography variant="subtitle1" color="text.primary" sx={{ mt: 0, mr: 1}}>Observe Company Holidays:</Typography>
          <Typography variant="body1" color="text.primary">{holidayCalendarId ? 'Yes' : 'No'}</Typography>
          {holidayCalendarId && <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'><InfoRounded color="primary" /></Tooltip>}
        </Box>
      }
      {title === 'Match Results Details' && annualDmf &&
        <Typography variant="subtitle1" mt={1} mb={0.5}>Annual DMF Delivery Date: <Typography display="inline">{months[Number(annualDmf?.split('/')[0])-1]} {Number(annualDmf?.split('/')[1])}</Typography></Typography>
      }
      <EmailAccordionWrapper>
        <Button className="pgp-button show-hide" variant="text" size="medium" onClick={toggleShowEmails}>Notification Emails {showEmails ? <ExpandLess /> : <ExpandMore />}</Button>
      </EmailAccordionWrapper>
      {showEmails &&
        <>
          {emails.map((email) => {
            return <Typography variant='subtitle1' key={email}>{email}</Typography>
          })}
        </>
      }
    </>
  )
}

DisplayMoreDetails.propTypes = {
  title: PropTypes.string, 
  auditDate: PropTypes.string, 
  auditUser: PropTypes.string, 
  frequency: PropTypes.string, 
  reportDates: PropTypes.array,
  frequencyDay: PropTypes.string, 
  holidayCalendarId: PropTypes.string,
  annualDmf: PropTypes.string,
  emails: PropTypes.array
}

export default DisplayMoreDetails;
