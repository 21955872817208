import React, { useEffect, useState } from 'react';

import { Button, ButtonGroup, Chip, Grid, styled } from '@mui/material';

import customerListColumns from './customerListColumns';
import { getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations } from 'src/api';
import deathListColumns from './deathListColumns';
import PageHeader from 'src/components/PageHeader';
import { ListAltRounded } from '@mui/icons-material';
import { useAppContext } from 'src/AppContext';
import { useQuery } from 'src/hooks';
import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';
import CollapsibleTable from 'src/components/CollapsibleTable';
import { switchFreqDisplay } from 'src/utilities/switchFrequencyDisplay';

const GridWrapper = styled(Grid)(
  () => `

@media screen and (max-width: 768px) {
  .MuiDataGrid-cell {
  min-width: 150px !important;
  }
  .MuiDataGrid-columnHeader {
    min-width: 150px !important;
  }
}
`
)

function ActiveListSummary() {
  const { carrierConfig: {carrierId} } = useAppContext();
  const [custFileConfig, setCustFileConfig] = useState(null);
  const [deathFileConfig, setDeathFileConfig] = useState(null);
  const [matchesFileConfig, setMatchesFileConfig] = useState([]);
  const [custListTableData, setCustListTableData] = useState(null);
  const [deathListTableData, setDeathListTableData] = useState(null);
  const [showDeathList, setShowDeathList] = useState(false);

  const customerFileConfigurationsQuery = useQuery('customerFileConfigurations', (() => getCustomerFileConfigurations(carrierId)));
  const deathFileConfigurationsQuery = useQuery('deathFileConfigurations', (() => getDeathFileConfigurations(carrierId)));
  const matchFileConfigurationsQuery = useQuery('matchFileConfigurations', (() => getMatchFileConfigurations(carrierId)));

  useEffect(() => {
    if(!customerFileConfigurationsQuery.isLoading) {
      setCustFileConfig(customerFileConfigurationsQuery.data);
    } 

    if(!deathFileConfigurationsQuery.isLoading) {
      setDeathFileConfig(deathFileConfigurationsQuery.data);
    }

    if(!matchFileConfigurationsQuery.isLoading) {
      setMatchesFileConfig(matchFileConfigurationsQuery.data);
    }
  }, 
  [customerFileConfigurationsQuery, 
   deathFileConfigurationsQuery, 
   matchFileConfigurationsQuery,
   setCustFileConfig,
   setDeathFileConfig,
   setMatchesFileConfig
  ]
);

  useEffect(() => {
    if (matchesFileConfig && custFileConfig) {
      let arrayMap = matchesFileConfig.map((item, index) => {
        const container = {};
        const matchedIndex = custFileConfig.findIndex(element => (element?.file?.prefix === item?.customerFilePrefix));
        const matchingCustomerFile = custFileConfig[matchedIndex];
        container.configurationType = item?.configurationType;
        container.customerList = item?.customerFilePrefix + '.' + matchingCustomerFile?.file?.type;
        container.id = index;
        container.matchWorkflow = item?.file?.type === 'both' ? item?.file?.prefix + '.json\n' + item?.file?.prefix + '.xlsx' : item?.file?.prefix + '.' + item?.file?.type;
        const _dmfAnnualMatchFileSample = item?.file?.type === 'both' ? `\n${item?.file?.prefix}_dmf_annual.json\n${item?.file?.prefix}_dmf_annual.xlsx` : `\n${item?.file?.prefix}_dmf_annual.${item?.file?.type}`;
        const _dmfAnnualMatchWorkflow = item?.transfer?.annualDmf ? _dmfAnnualMatchFileSample : '';
        const _dmfNickname = item?.transfer?.annualDmf && item?.nickname ? `\n${item?.nickname}` : ''
        container.matchWorkflow = `${container.matchWorkflow}${_dmfNickname}${_dmfAnnualMatchWorkflow}`;
        container.annualDmf = item?.transfer?.annualDmf
        container.sources = item?.sources.map((source) => sourceDisplayMap[source]).join(',\n');
        container.matchCategories = item?.categories.map((cat) => {
          return <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px', mb: '3px', width: '50px'}} size="small" label={cat} />
        });
        container.matchAuditDate = item?.auditDate;
        container.matchAuditUser = item?.auditUser;
        container.matchFrequency = switchFreqDisplay(item?.transfer?.frequency);
        container.matchReportDates = item?.transfer?.reportDates;
        container.matchFrequencyDay = item?.transfer?.frequencyDay;
        container.matchHolidayCalendarId = item?.holidayCalendarId;
        container.matchNotificationEmails = item?.notificationEmails;
        container.matchNickname = item?.nickname;
        container.custAuditDate = matchingCustomerFile?.auditDate;
        container.custAuditUser = matchingCustomerFile?.auditUser;
        container.custFrequency = switchFreqDisplay(matchingCustomerFile?.transfer?.frequency);
        container.custFrequencyDay = matchingCustomerFile?.transfer?.frequencyDay;
        container.custNotificationEmails = matchingCustomerFile?.notificationEmails;
        container.custNickname = matchingCustomerFile?.nickname;
        return container;
      })
      setCustListTableData(arrayMap);
    }

    if (deathFileConfig) {
      let arrayMap = deathFileConfig.map((item, index) => {
        const container = {};
        container.configurationType = item?.configurationType;
        container.deathList = item?.file?.prefix + '.' + item?.file?.type;
        container.id = index;
        container.deathAuditDate = item?.auditDate;
        container.deathAuditUser = item?.auditUser;
        container.deathFrequency = switchFreqDisplay(item?.transfer?.frequency);
        container.deathFrequencyDay = item?.transfer?.frequencyDay;
        container.deathNotificationEmails = item?.notificationEmails;
        container.deathNickname = item?.nickname;
        return container;
      })
      setDeathListTableData(arrayMap);
    }
  }, [matchesFileConfig, deathFileConfig, custFileConfig]);

  return (
    <>
      <PageHeader titlePaddingTop={'15px'} title="Active List Summary" icon={<ListAltRounded />} />

      <GridWrapper>
        <ButtonGroup sx={{ mb: 4 }}>
          <Button onClick={() => setShowDeathList(false)} variant={showDeathList ? "outlined" : "contained"}>Customer Lists</Button>
          <Button onClick={() => setShowDeathList(true)} variant={showDeathList ? "contained" : "outlined"}>Verified Death Lists</Button>
        </ButtonGroup>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2} sx={{ pr: 2, pb: 1, pt: 0 }}>
              <Grid item xs={12}>
                {!showDeathList &&
                <CollapsibleTable columns={customerListColumns} data={custListTableData} alignContent="start" message="No lists to display."/>
                }
                {showDeathList &&
                <CollapsibleTable columns={deathListColumns} data={deathListTableData} alignContent="start" message="No lists to display."/>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridWrapper>
    </>

  );
}

export default ActiveListSummary;