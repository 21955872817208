import React, { useEffect, useState } from 'react';

import { Grid, styled, Typography, Paper, Chip } from '@mui/material';
import PageHeader from 'src/components/PageHeader';
import { HistoryRounded } from '@mui/icons-material';
import { useAppContext } from 'src/AppContext';
import { sourceDisplayMap } from 'src/utilities/mapSourceDisplay';
import activityLogColumns from './columns';
import TableWithActionButton from 'src/components/TableWithActionButton';
import { useQuery } from 'src/hooks';
import { getAuditConfigEntries } from 'src/api';
import SkeletonRows from './SkeletonRows';

const GridWrapper = styled(Grid)(
  () => `
    @media screen (max-width: 768px) {
      .MuiDataGrid-cell {
        min-width: 150px !important;
      }
      .MuiDataGrid-columnHeader {
        min-width: 150px !important;
      }
    }
  `
)

const displayAttributes = [
  'sources',
  'categories'
];

function ActivityLog() {
  const { carrierConfig: {carrierId} } = useAppContext();
  const [tableData, setTableData] = useState([]);

  const {isLoading, data: auditEntries} = useQuery('auditConfigEntries', (() => getAuditConfigEntries(carrierId, 'matches')));


  useEffect(() => {
    const dateOptions = {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit',
      timeZoneName: 'short'
    };

    if (auditEntries) {
      const filteredEntries = auditEntries.reduce(function(result, item) {
        if(displayAttributes?.includes(item?.attributeChanged) || item?.operation === 'INSERT'){
          result.push(item)
        }
        return result;
      }, []);
 
      let arrayMap = filteredEntries.map((item, index) => {
        const container = {};
        container.id = index;
        container.fileName = item?.extension === 'both' ? `${item?.prefix}.json\n${item?.prefix}.xlsx` : `${item?.prefix}.${item?.extension}`; 
        container.time = new Date(item?.auditDate).toLocaleDateString('en-US', dateOptions );
        container.sortableTime = item?.auditDate;
        container.sources = item?.sources.length === 0 ? '-' : item?.sources.map((source) => sourceDisplayMap[source]).join(',\n');
        container.categories = item?.categories.map((cat) => {
          return <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px', mb: '3px', width: '50px'}} size="small" label={cat} />
        });
        container.enteredBy = item?.auditUser;
        container.fileNameNickname = item?.nickname;

        return container;
      })      
      setTableData(arrayMap);
    }
  }, [auditEntries]);

  return (
    <>
      <PageHeader titlePaddingTop={'15px'} title="Activity Log" icon={<HistoryRounded />} />
      <GridWrapper>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2} sx={{ pb: 1, pt: 1 }}>
              <Typography variant="h5" sx={{ ml: 2 }}>Source & Match Category Activity</Typography>
              <Grid item xs={12} sx={{mt: 1}}>
                {auditEntries?.length === 0 && !isLoading ? 
                  <Grid component={Paper} item sx={{p: 4}}>
                    <Typography variant='h6' fontSize='medium'>No activity to display.</Typography>
                  </Grid>
                  :
                  <TableWithActionButton
                    skeleton={<SkeletonRows />}
                    isDataTableLoading={isLoading}
                    rows={tableData}
                    columns={activityLogColumns}
                    tableHeight={775} 
                    useAutoRowHeight={true}
                    alignContent="start"
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridWrapper>
    </>
  );
}

export default ActivityLog;