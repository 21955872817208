import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Divider, Grid, Box } from '@mui/material';
import DisplayMoreDetails from './displayMoreDetails';
import PropTypes from 'prop-types';
import DisplayMoreDetailsMatchResults from './displayMoreDetailsMatchResults';
import DisplayMoreDetailsCustomerDataLoads from './displayMoreDetailsCustomerDataLoads';

function CollapsibleRow({ row, columns, alignContent = 'center' }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '20px', borderBottom: 'unset', alignContent: alignContent }}>
          {row?.fileType === 'customer-files' && (row?.fileStatus?.props?.label === 'Failed' || row?.fileStatus?.props?.label === 'Processing') ?
            <></>
            :
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        {columns?.map((column) => (
          <TableCell key={column?.field} align="left" sx={{ alignContent: alignContent, borderBottom: 'unset' }}><Typography variant="body2" sx={{ 'whiteSpace': 'pre-line' }}>
            {column?.field === 'customerList' && row?.custNickname &&
              <>
                {row?.custNickname} <br />
              </>
            }
            {column?.field === 'deathList' && row?.deathNickname &&
              <>
                {row?.deathNickname} <br />
              </>
            }
            {(column?.field === 'matchWorkflow' || column?.field === 'fileName') && row?.matchNickname && row?.status !== 'Skipped' &&
              <>
                {row?.matchNickname} <br />
              </>
            }
            {column?.field === 'sources' && row[column?.field].length === 0 && '-'}

            {column?.field === 'sources' && row[column?.field].length === 0 && '-'}

            {column?.field === 'submittedSortable' &&
              <>
                {row['submitted']}
              </>
            }

            {column?.field === 'completedSortable' &&
              <>
                {row['completed']}
              </>
            }

            {column?.field !== 'submittedSortable' && column?.field !== 'completedSortable' && row[column?.field]}
          </Typography>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns?.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container sx={{ p: 2 }}>
                {row?.configurationType === 'matches' &&
                  <>
                    <Grid item xs={5}>
                      <DisplayMoreDetails title='Customer List Details' auditDate={row?.custAuditDate} auditUser={row?.custAuditUser} frequency={row?.custFrequency} frequencyDay={row?.custFrequencyDay} emails={row?.custNotificationEmails} />
                    </Grid>
                    <Grid item xs={1}><Divider sx={{ height: '105% !important', mt: '-4px' }} orientation="vertical" variant="middle" display="flex" /></Grid>
                    <Grid item xs={5}>
                      <DisplayMoreDetails title='Match Results Details' auditDate={row?.matchAuditDate} auditUser={row?.matchAuditUser} frequency={row?.matchFrequency} reportDates={row?.matchReportDates} frequencyDay={row?.matchFrequencyDay} holidayCalendarId={row?.matchHolidayCalendarId} annualDmf={row?.annualDmf} emails={row?.matchNotificationEmails} />
                    </Grid>
                  </>
                }
                {row?.configurationType === 'death_records' &&
                  <Grid item xs={5}>
                    <DisplayMoreDetails title='Verified Deaths List Details' auditDate={row?.deathAuditDate} auditUser={row?.deathAuditUser} frequency={row?.deathFrequency} frequencyDay={row?.deathFrequencyDay} emails={row?.deathNotificationEmails} />
                  </Grid>
                }
                {row?.fileType === 'customer-files' &&
                  <Grid item xs={12}>
                    <DisplayMoreDetailsCustomerDataLoads title='Record Overview' totalRecords={row?.totalRecords} numOfErrors={row?.numOfErrors} missingCounts={row?.missingCounts} />
                  </Grid>
                }
                {row?.variant === 'match results table' &&
                  <Grid item xs={12}>
                    {row?.status === 'Skipped' ?
                      <Typography variant='body2'>{row?.note}. View <Link to={'/holiday-calendar'}>Holiday Calendar</Link></Typography>
                      :
                      <DisplayMoreDetailsMatchResults title='Match Records Overview:' newMatches={row?.newMatches} deletedMatches={row?.deletedMatches} otherMatches={row?.otherMatches} />
                    }
                  </Grid>
                }
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleRow.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.array,
  alignContent: PropTypes.string
}


export default CollapsibleRow;